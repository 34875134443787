<template lang="html">
    <div class="chat">
        <NoResult
            v-if="!messages.length"
            type="writers-messages"
        />
        <div
            v-else
            ref="chat_wrapper"
            class="chat-wrapper"
        >
            <message
                v-for="(message, message_index) in messages"
                :key="message_index"
                :message="message"
                :nickname="order.writer && order.writer.nickname"
            />
        </div>

        <div class="chat-message_form">
            <send-message-form
                :last-message="lastMessage"
                :is-loading="isLoading"
                @sendMessage="sendMessage"
                @typing="typingListener"
            >
                <p v-if="typingMessage">
                    {{ typingMessage }}
                </p>
            </send-message-form>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus'
import Api from '@/helpers/api/index.js';

// Components
import NoResult from '@/components/common/NoResult'
import Message from '@/components/common/Chat/Message.vue'
import SendMessageForm from '@/components/common/Chat/SendMessageForm.vue'

export default {
    components: {
        NoResult,
        Message,
        SendMessageForm
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        messages: [],
        message: '',
        isLoading: false,
        typing: false,
        typingMessage: ''
    }),
    computed: {
        ...mapGetters('client', [
            'getterMainClientInfo'
        ]),
        lastMessage() {
            return this.messages[this.messages.length - 1]
        }
    },
    watch: {
        typing(newVal) {
            this.$socket.emit('order:typing', {
                typing: newVal,
                orderid: this.order.orderid
            })
        }
    },
    async created() {
        await this.markAsRead()
        this.messages = [...this.order.messages]
        this.$socket.on('order:typing', (data) => {
            if (data.role === 'Client' || data.role === 'Lead') return
            this.typingMessage = data.message
        })
        this.$socket.on('order:newMessage', (data) => {
            if (data.author.id === this.getterMainClientInfo.id) return
            this.messages = [...this.messages, data]
            this.$nextTick(() => {
                this.scrollMessages()
            })
        })
    },
    mounted() {
        this.scrollMessages()
    },
    methods: {
        typingListener(value) {
            this.typing = value
        },
        async markAsRead() {
            await Api.put('/api/order-message/auth/mark-as-read', {
                orderid: this.order.orderid
            })
            setTimeout(() => {
                this.$emit('markAsRead')
            }, 10000);
        },
        scrollMessages() {
            const elem = this.$refs.chat_wrapper
            elem.scrollTo({ top: 99999999, behavior: 'smooth' })
        },
        async sendMessage({ message }) {
            this.isLoading = true;
            try {
                const { data } = await Api.post('/api/order-message/auth/create-message', {
                    orderid: this.order.orderid,
                    message
                })
                this.messages.push(data)
                this.$nextTick(() => { this.scrollMessages() })
                this.$emit('actionAddMessage')
                eventBus.$emit('showSnackBar', 'Your message has been received', 'success');
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.isLoading = false
                this.$v.$reset()
            }
        }
    }
}
</script>

<style lang="scss">
    .chat-wrapper{
        display: flex;
        flex-direction: column;
    }
    .chat{
        padding: 0 15px;
        padding-bottom: 30px;
    }
</style>
